import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import './Qrcode.css';

function POC() {
  const BASE_URL = `https://api.khatatracker.com`;
  const [invoiceData, setInvoiceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (!hash) {
      setError('No hash found in the URL');
      setLoading(false);
      return;
    }

    const apiUrl = `${BASE_URL}/salerandom/${hash}`;

    const fetchInvoice = async () => {
      try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        setInvoiceData(data.saleInvoice);
      } catch (err) {
        setError('Error fetching invoice data.');
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
  }, []);

  if (loading) return <p>Loading invoice data...</p>;
  if (error) return <p style={{ color: 'red' }}>{error}</p>;

  return (
    <>
      {invoiceData ? (
        <div style={{ padding: '20px', backgroundColor: '#FFFFFF', maxWidth: '600px', margin: 'auto', fontFamily: 'Courier Prime' }} id='pdf-content'>
          <h2 style={{ textAlign: 'center' }} className='print'>{invoiceData.myDetails?.name || ''}</h2>
          <p style={{ textAlign: 'center' }} className='print'>Ph.No:{invoiceData.myDetails?.phoneNumber || ''}</p>
          <hr style={{ border: 'none', borderBottom: '1px dashed #000', margin: '10px 0' }} />

          <div style={{ marginTop: '20px', marginLeft: "30px" }}>
            <strong className='print'>To</strong>
            <p className='mt-2 print'>{invoiceData.customerDetails?.name || ''}</p>
            <p>{invoiceData.customerDetails?.phoneNumber || ''}</p>
          </div>

          <hr style={{ border: 'none', borderBottom: '1px dashed #000', margin: '10px 0' }} />

          <h3 style={{ textAlign: 'center', margin: '20px 0' }} className='print'>SALE BILL</h3>

          <p style={{ display: 'flex', justifyContent: "center" }}>
            <span style={{ fontWeight: 'bold' }} className='print'>Invoice No:</span>&nbsp;&nbsp;<span className='print'>{invoiceData.invoiceNumber || ''}</span>
          </p>
          <p style={{ justifyContent: "center", display: 'flex' }} className='print'>
            <span style={{ fontWeight: 'bold' }}>Invoice Date:</span>&nbsp;&nbsp;
            <span>{invoiceData.date ? format(new Date(invoiceData.date), 'dd/MM/yyyy') : ''}</span>
          </p>

          <table className='POCtable'>
            <thead>
              <tr className='print'>
                <th>S.No</th>
                <th>Items</th>
                <th>Qty</th>
                <th>Tax</th>
                <th>Dis</th>
                <th>Amt</th>
              </tr>
            </thead>
            <tbody>
              {invoiceData.items?.map((item, index) => (
                <tr key={index} className="sale-bill">
                  <td>{item.SlNo}</td>
                  <td>{item.itemName}</td>
                  <td>{item.quantity} {item.quantityUnits}</td>
                  <td>{item.tax || 0}{item.taxunits}</td>
                  <td>{item.discount || 0}{item.disunits}</td>
                  <td>{item.total}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className='poc_gst'>
            <p className='poc_row'>Tax: <span className="value-gap">{invoiceData.tax || 0}{invoiceData.taxunits}</span></p>
            <p className='poc_row'>Discount: <span className="value-gap">{invoiceData.discount || 0}{invoiceData.disunits}</span></p>

            {invoiceData.AdditionalCharges?.map((charge, index) => (
              <p key={index} className="poc_row">{charge.key}<span className="value-gap">{charge.value}</span></p>
            ))}

            <hr style={{ border: 'none', borderBottom: '1px dashed #000', margin: '10px 0' }} />

            <p className='poc_row'><b>Net Amount:</b> <span className="value-gap">{invoiceData.total}</span></p>

            {invoiceData.payments?.map((payment, index) => (
              <p key={index} className="poc_row">Amount Received: <span className="value-gap">{parseFloat(payment.AmountReceived).toFixed(2)}</span></p>
            ))}

            <p className="poc_row"><b>Balance:</b> <span className="value-gap">{parseFloat(invoiceData.payments?.[invoiceData.payments.length - 1]?.BalanceAmount || invoiceData.total).toFixed(2)}</span></p>
          </div>
        </div>
      ) : (
        <p>No data available</p>
      )}
    </>
  );
}

export default POC;
