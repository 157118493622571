import React, { useEffect } from "react";
import "./gallery.css";

const Gallery = () => {
  useEffect(() => {
    if (window.jQuery) {
      window.$("#screen_slider").owlCarousel({
        loop: true,
        margin: 30,
        dots: true,
        autoplay: true,
        autoplayTimeout: 2500,
        autoplaySpeed: 200,
        autoplayHoverPause: false, 
        smartSpeed: 100, 
        autoplayHoverPause: true,
        rewind: false, 
        center: true,
        responsive: {
          0: { items: 3 },  // Mobile
          480: { items: 3 }, // Small screen
          768: { items: 3 }, // Tablet
          1024: { items: 5 }, // Laptop
          1200: { items: 5 }, // Large screens
        },
      });
    }
    return () => {
      if (window.jQuery) {
        window.$("#screen_slider").trigger("destroy.owl.carousel");
      }
    };
  }, []);

  return (
    <section id="gallery_page" style={{ paddingTop: "50px" }}>
      <div className="gallery-section">
        <div className="page-title">
          <hr className="left-hr" />
          <h3>Feature Images</h3>
          <hr className="right-hr" />
        </div>
        <h3 className="sub-heading">Some Screenshots of the App</h3>
        <div className="screen_slider">
          <div id="screen_slider" className="owl-carousel owl-theme">
            <div className="item"><div className="screen_frame_img"><img src="./images/01.webp" alt="image" loading="lazy" /></div></div>
            <div className="item"><div className="screen_frame_img"><img src="./images/02.webp" alt="image" loading="lazy" /></div></div>
            <div className="item"><div className="screen_frame_img"><img src="./images/03.webp" alt="image" loading="lazy" /></div></div>
            <div className="item"><div className="screen_frame_img"><img src="./images/04.webp" alt="image" loading="lazy" /></div></div>
            <div className="item"><div className="screen_frame_img"><img src="./images/05.webp" alt="image" loading="lazy" /></div></div>
            <div className="item"><div className="screen_frame_img"><img src="./images/06.webp" alt="image" loading="lazy" /></div></div>
            <div className="item"><div className="screen_frame_img"><img src="./images/07.webp" alt="image" loading="lazy" /></div></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
