import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './assets/js/main.js';
// import './assets/js/vendor/modernizr-2.8.3.min.js';
import { Route, Routes, useLocation } from 'react-router-dom';
import Nav from './components/nav';
import Footer from './components/footer';
import About from './components/about';
import Home from './components/home';
import Features from './components/features';
import Gallery from './components/gallery';
import Overview from './components/overView';
import OurProcess from './components/ourprocess';
import FAQ from './components/faq';
import Contact from './components/contact';
import Download from './components/download';
import DeleteAccountInstructions from './components/manage';
import DeleteAccount from './components/delete';
import StandaloneManageAccount from './components/StandaloneManageAccount';
import SMS_View from './SMS_View';
import CancellationRefundPolicy from './components/refund';
import Notfoundpage from './components/notfoundpage';
import POC from './Qrcode';
import PricingPlans from './components/pricing';
// import Register from './pages/register/Register';
// import OTPVerification from './pages/Verify_OTP/verifyotp';
function App() {
  // useEffect(() => {
  //   const disableRightClick = (event) => event.preventDefault();
  //   document.addEventListener("contextmenu", disableRightClick);
    
  //   return () => {
  //     document.removeEventListener("contextmenu", disableRightClick);
  //   };
  // }, []);
  
  return (
    <div className="App">
      {/* <Routes>
        <Route path="/delete-account" element={<StandaloneManageAccount />} />
        <Route path="/refund" element={<CancellationRefundPolicy />} />
        <Route path="/manage" element={<DeleteAccountInstructions />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/features" element={<Features />} />
        <Route path="/overview" element={<Overview />} />
        <Route path="/our-process" element={<OurProcess />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/download" element={<Download />} />
        <Route path="/contact" element={<Contact />} />
        <Route path='/i' element={<SMS_View />} />
        <Route path="/s" element={<POC/>}/>
      </Routes> */}
      <Nav />
      <Home />
      <About />
      <OurProcess />
      <Features />
      <Overview />
      <Gallery/>
      <FAQ />
      <PricingPlans/>
      <Download />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;

// import 'bootstrap/dist/css/bootstrap.min.css';
// import Nav from './components/nav';
// import Footer from './components/footer';
// import About from './components/about';
// import Home from './components/home';
// import Features from './components/features';
// import Gallery from './components/gallery';
// import Overview from './components/overView';
// import OurProcess from './components/ourprocess';
// import FAQ from './components/faq';
// import Contact from './components/contact';
// import Download from './components/download';
// import { Route, Routes } from 'react-router-dom';
// import DeleteAccountInstructions from './components/manage';
// import DeleteAccount from './components/delete';

// function App() {
//   return (
//     <div className="App">
//       <Nav />
//       <Routes>
//         <Route path="/delete-account" element={<DeleteAccount />} />
//         <Route path="/delete-account-instructions" element={<DeleteAccountInstructions />} />
//         <Route path="/" element={<Home />} />
//         <Route path="/about" element={<About />} />
//         <Route path="/features" element={<Features />} />
//         <Route path="/overview" element={<Overview />} />
//         <Route path="/our-process" element={<OurProcess />} />
//         <Route path="/gallery" element={<Gallery />} />
//         <Route path="/faq" element={<FAQ />} />
//         <Route path="/download" element={<Download />} />
//         <Route path="/contact" element={<Contact />} />
//       </Routes>
//       <Footer />
//     </div>
//   );
// }

// export default App;

