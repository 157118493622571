import React from 'react';
import './styles.css';
import process from '../assets/images/progress-phone (1).webp';

const OurProcess = () => {
  return (
    <div>
      <section className="progress-area gray-bg" id="progress_page">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div>
                {/* Changed h5 to h2 for better accessibility */}
                <h2 className="title d-flex align-items-center justify-content-center pt-5">
                  <span className="mx-3 fs-2 fw-bold">WHY CHOOSE KHATA TRACKER?</span>
                </h2>

                <div className="desc wow fadeInUp" data-wow-delay="0.6s">
                  <ul style={{ textAlign: 'left' }}>
                    <li><span className="fw-semibold">Easy to Use:</span> A simple and intuitive interface designed for smooth <span className="fw-bold">financial record management.</span></li>
                    <li><span className="fw-semibold">Multiple Accounts:</span> Create and manage <span className="fw-bold">separate ledgers</span> for business and personal finances.</li>
                    <li><span className="fw-semibold">Live Balance Updates:</span> Instantly view <span className="fw-bold">account balances and transaction history</span> for better cash flow management.</li>
                    <li><span className="fw-semibold">Fully Customizable:</span> Rename <span className="fw-bold">ledgers, categories, and transaction types</span> to suit your business requirements.</li>
                    <li><span className="fw-semibold">Export & Share Data:</span> Save and share <span className="fw-bold">financial reports</span> in PDF format for easy <span className="fw-bold">record-keeping and auditing.</span></li>
                    <li><span className="fw-semibold">Automatic Backup:</span> Securely store and sync your data across multiple devices with <span className="fw-bold">cloud backup.</span></li>
                    <li><span className="fw-semibold">Safe & Secure Login:</span> Protect sensitive information using <span className="fw-bold">passcode or fingerprint authentication.</span></li>
                    <li><span className="fw-semibold">Add Notes & Images:</span> Attach <span className="fw-bold">notes and photos</span> to transactions for detailed <span className="fw-bold">record-keeping and tracking.</span></li>
                  </ul>
                </div>

                <div className="space-50"></div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <figure className="mobile-image">
                <img src={process} alt="Progress Phone" />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default OurProcess;