import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import for React 18
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import StandaloneManageAccount from './components/StandaloneManageAccount';
import DeletealoneManageAccount from './components/DeletealoneManageAccount';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import SMS_View from './SMS_View';
import CancellationRefundPolicy from './components/refund';
import DeleteAccount from './components/delete';
import POC from './Qrcode';

// Create the root element using the new React 18 API
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the app using the new `root.render()` method
root.render(
  <Router
    future={{
      v7_startTransition: true, // Enable React Router's startTransition feature
      v7_relativeSplatPath: true,
    }}
  >
    <Routes>
      <Route path="/login-delete" element={<DeleteAccount />} />
      <Route path="/delete-account" element={<StandaloneManageAccount />} />
      <Route path='/i' element={<SMS_View />} />
      <Route path='/s' element={<POC />} />
      <Route path="/refund" element={<CancellationRefundPolicy />} />
      <Route path="/*" element={<App />} />
    </Routes>
  </Router>
);
