import React, { useState } from 'react';
// import './styles.css';
const FAQ = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const toggleCollapse = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };
  const faqs = [
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "Is Khata Tracker available on both Android and iOS?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, Khata Tracker is available for download on both Android and iOS platforms."
          }
        },
        {
          "@type": "Question",
          "name": "Can I use Khata Tracker offline?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, you can record transactions offline, and the app will sync your data once you are connected to the internet."
          }
        },
        {
          "@type": "Question",
          "name": "How do I back up my data in Khata Tracker?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Khata Tracker automatically backs up your data to the cloud, ensuring it's safe and accessible across devices."
          }
        },
        {
          "@type": "Question",
          "name": "Can I customize the categories for my expenses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, you can easily create and customize categories to suit your specific needs."
          }
        },
        {
          "@type": "Question",
          "name": "Is my data secure in Khata Tracker?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, your data is protected with secure login methods like passcode and fingerprint authentication."
          }
        },
        {
          "@type": "Question",
          "name": "Can I export my transaction data?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, you can export your transaction history and reports in PDF format for easy record keeping."
          }
        },
        {
          "@type": "Question",
          "name": "How do I reset my Khata Tracker password?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "You can reset your password by going to the 'Forgot Password' option on the login screen and following the instructions."
          }
        },
        {
          "@type": "Question",
          "name": "Does Khata Tracker support multiple currencies?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, Khata Tracker allows you to manage transactions in multiple currencies based on your preferences."
          }
        },
        {
          "@type": "Question",
          "name": "Can I set reminders for upcoming payments or dues?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, Khata Tracker allows you to set reminders for bills or payments to help you stay on track."
          }
        },
        {
          "@type": "Question",
          "name": "Is there a limit to the number of ledgers I can create?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "No, you can create unlimited ledgers to manage different accounts or businesses."
          }
        },
        {
          "@type": "Question",
          "name": "Can Khata Tracker be used by multiple users?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, Khata Tracker can be used by multiple users, but each user needs their own account to keep their data separate and secure."
          }
        },
        {
          "@type": "Question",
          "name": "Does Khata Tracker share data with the government?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "No, Khata Tracker does not share your data with the government. Your information remains private and secure."
          }
        }
      ]
    }
  ];
  return (
    <div>
      <section className="questions-area section-padding" id="faqs_page" style={{ textAlign: 'left' }}>
        <div className="container-fluid" style={{ paddingTop: "80px" }}>
          <div className="row">
            <div className="col-xs-12">
              <div className="page-title text-center">
                <p className="title" style={{ marginBottom: '-20px' }}>FAQ</p>
                <h3 className="dark-color pb-5" style={{ color: '#086351' }}>Frequently Asked Questions</h3>
                <div className="space-60"></div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* First 6 FAQs */}
            <div className="col-xs-12 col-md-6 toggole-boxs">
              {faqs[0].mainEntity.slice(0, 6).map((item, index) => (
                <div key={index} className="faq-item">
                  <h3
                    className={expandedIndex === index ? 'expanded' : ''}
                    onClick={() => toggleCollapse(index)}
                  >
                    {item.name}
                  </h3>
                  {expandedIndex === index && (
                    <div style={{ borderLeft: 'none' }}>
                      <p>{item.acceptedAnswer.text}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {/* Next 6 FAQs */}
            <div className="col-xs-12 col-md-6 toggole-boxs">
              {faqs[0].mainEntity.slice(6).map((item, index) => (
                <div key={index + 6} className="faq-item">
                  <h3
                    className={expandedIndex === index + 6 ? 'expanded' : ''}
                    onClick={() => toggleCollapse(index + 6)}
                  >
                    {item.name}
                  </h3>
                  {expandedIndex === index + 6 && (
                    <div style={{ borderLeft: 'none' }}>
                      <p>{item.acceptedAnswer.text}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default FAQ;