import React from 'react';
import './styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons';

const Overview = () => {
  return (
    <div>
      <section className="section-padding"style={{ textAlign: 'left' }}>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <div className="page-title" style={{marginTop:"-100px"}}>
                <h5 className="title wow fadeInUp" data-wow-delay="0.2s"style={{ textAlign: 'left' }}>App OverView</h5>
                <div className="space-10"></div>
                <ul style={{ textAlign: 'left' }}className=" wow fadeInUp" data-wow-delay="0.4s"><li>Effortless Expense Logging: Add income and expenses quickly with a user-friendly interface.</li>
                  <li>Smart Categorization: Automatically sort transactions into customizable categories.</li>
                  <li>Budget Management: Set spending limits for different categories and receive helpful alerts.</li>
                  <li>Insightful Reports & Visual Charts: Analyze spending patterns with clear reports and charts.</li>
                  <li>Bill Payment Reminders: Get timely notifications to avoid missed payments.</li>
                  <li>Multi-Device Synchronization: Sync data across multiple devices for easy access anytime, anywhere.</li>
                  <li>Robust Security Measures: Protect financial data with top-notch encryption protocols.</li>
                  <li>User-friendly interface for a hassle-free experience.</li>
                </ul>

                <div className="space-20"></div>
                <div className="desc wow fadeInUp" data-wow-delay="0.6s">
                  <p></p>
                </div>
                <div className="space-50"></div>
                {/* <a href="#" className="bttn-default wow fadeInUp" data-wow-delay="0.8s">Learn More</a> */}
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-5 col-md-offset-1">
              <div className="space-60 hidden visible-xs"></div>
              <div className="service-box wow fadeInUp" data-wow-delay="0.2s">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faIndianRupeeSign} aria-hidden="true" />
                </div>
                <h4><b>Our Income Section</b></h4>
                <p>Easily enter <b>income details</b> such as salary, business earnings, or other sources.</p>
              </div>
              <div className="space-50"></div>
              <div className="service-box wow fadeInUp" data-wow-delay="0.2s">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faIndianRupeeSign} aria-hidden="true" />
                </div>
                <h4><b>Spending Amount Section</b></h4>
                <p>Log <b>expenses</b> related to food, entertainment, utilities, and more to maintain accurate records.</p>
              </div>
              <div className="space-50"></div>
              <div className="service-box wow fadeInUp" data-wow-delay="0.2s">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faIndianRupeeSign} aria-hidden="true" />
                </div>
                <h4><b>Available Balance</b></h4>
                <p>Automatically calculate available balances by deducting <b>expenses from income.</b></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Overview;
