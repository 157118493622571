import React from 'react';
// import './styles.css';
import download from '../assets/images/download-image.webp';
import play from '../assets/images/PlayStoreBadge.webp';
import google from '../assets/images/GooglePlayStore.webp';

const Download = () => {
  return (
    <div>
      <section id="download_page" >
        <div className="download-area overlay">
          <div className="container" style={{ paddingTop: '50px' }}>
            <div className="row">
              <div className="col-xs-12 col-sm-6 hidden-sm">
                <figure className="d-flex align-items-center">
                  <img src={download} alt="Download the Khata Tracker App" />
                </figure>
              </div>
              <div className="col-xs-12 col-md-6 section-padding">
                <h2 className="white-color" style={{ textAlign: 'left' }}>
                  <b>Download the Khata Tracker App Today!</b>
                </h2>

                {/* Proper Heading Order (h4 instead of h5) */}
                <h4 className="zoom-text">
                  <b>
                    <span>
                      Stay on Top of Your Finances with Khata Tracker!
                    </span>
                  </b>
                </h4>

                <style jsx>{`
  /* General Styling for the text */
  .zoom-text span {
    padding: 10px;
    color: #fff;
    display: flex;
    flexWrap: 'wrap';
    justify-content: center;
    text-align: center;
    background-color: green;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
    transition: transform 0.3s ease-in-out; 
  }

  /* Zoom effect on hover */
  .zoom-text span:hover {
    transform: scale(1.02);
  .zoom-text {
    display:flex;
  }
  .zoom-text span {
    max-width: 100%; 
    width: 100%;
    margin: 0 auto;
    padding:10px;
  }
  @media (max-width: 768px) {
    .zoom-text span {
      font-size: 14px; 
      padding: 8px;
    }
  }

  @media (min-width: 769px) and (max-width: 1200px) {
    .zoom-text span {
      font-size: 18px; /* Adjust font size for medium screens */
      padding: 10px;
    }
  }

  @media (min-width: 1201px) {
    .zoom-text span {
      font-size: 22px; /* Adjust font size for large screens */
      padding: 12px;
    }
  }
`}</style>

                <div className="space-20"></div>

                <p style={{ textAlign: 'left' }}>
                  <b>Khata Tracker</b> is the perfect <b>finance tracking app</b> for businesses looking to manage
                  <b> customer transactions, invoices, and ledgers</b> in one place. Instantly view detailed
                  <b> transaction histories</b> and generate professional <b>financial reports</b> for customer references.
                  Gain complete control over your <b>business finances</b> and experience effortless
                  <b> expense management</b> with Khata Tracker.
                </p>

                <p style={{ textAlign: 'left' }}>
                  <b>Download Now</b> to simplify your business accounts and stay ahead with real-time financial insights!
                </p>

                <div className="space-60"></div>

                {/* App Store & Play Store Buttons */}
                <a href="https://apps.apple.com/in/app/khata-book-my-spending-tracker/id1517793704"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Download on the App Store">
                  <img src={play} alt="Download on the App Store" style={{ marginLeft: "10px" }} />
                </a>

                <a href="https://play.google.com/store/apps/details?id=personal.expense.budget.pocket.moneytracker"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Get it on Google Play">
                  <img src={google} alt="Get it on Google Play" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Download;
