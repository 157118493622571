import React, { useEffect, useState } from 'react';
import Img from '../assets/images/KT_Logo_white.webp';
import '../components/nav.css';
import arrow from '../assets/images/left-arrow.gif';
import { useLocation } from 'react-router-dom';
import SEO from '../components/SEO';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const Navbar = () => {
  const [activeLink, setActiveLink] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [seoTitle, setSeoTitle] = useState("Khata Tracker - Best Digital Accounting Book for Small Businesses");
  const [seoDescription, setSeoDescription] = useState("Udhar Khata Spending Tracker is the #1 accounting app to track Personal & Business Sales & Purchases with Live Reports & Analysis to simplify Profit & Loss accounting processes and save time!");

  const toggleMenu = () => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  };

  useEffect(() => {
    const scrollToSection = (sectionId) => {
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
        setActiveLink(sectionId);
      }
    };

    const updateActiveLinkOnScroll = () => {
      const sections = document.querySelectorAll("section");
      let currentSection = "";
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
          currentSection = section.id;
        }
      });
      setActiveLink(currentSection);
    };

    const anchors = document.querySelectorAll('#primary_menu a[data-section]');
    const handleAnchorClick = (e) => {
      e.preventDefault();
      const targetId = e.currentTarget.getAttribute('data-section');
      scrollToSection(targetId);
      const customName = e.currentTarget.getAttribute('data-name');
      window.history.pushState(null, '', `/${customName}`);
      setMenuOpen(false);
    };

    anchors.forEach((anchor) => {
      anchor.addEventListener('click', handleAnchorClick);
    });
    window.addEventListener("scroll", updateActiveLinkOnScroll);

    return () => {
      anchors.forEach((anchor) => {
        anchor.removeEventListener('click', handleAnchorClick);
      });
      window.removeEventListener("scroll", updateActiveLinkOnScroll);
    };
  }, []);

  return (
    <>
      <SEO title={seoTitle} description={seoDescription} />
      <nav className="mainmenu-area navbar navbar-expand-lg navbar-light" data-spy="affix" data-offset-top="200">
        <div className="container-fluid d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <button
              type="button"
              className={`navbar-toggler ${menuOpen ? 'collapsed' : ''}`}
              onClick={toggleMenu}
              style={{ backgroundColor: '#fff' }}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <a className="navbar-brand ml-2" href="index">
              <img src={Img} alt="Logo" className="nav_img" loading='lazy'/>
            </a>
          </div>
          <div className={`collapse navbar-collapse justify-content-end ${menuOpen ? 'show' : ''}`} id="primary_menu">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item mx-2">
                <a className={`text-decoration-none nav-link ${activeLink === 'home_page' ? 'active' : ''}`} data-section="home_page" data-name="home" href="#home_page">
                  Home
                </a>
              </li>
              <li className="nav-item mx-2">
                <a className={`text-decoration-none nav-link ${activeLink === 'about_page' ? 'active' : ''}`} data-section="about_page" data-name="about-us" href="#about_page">
                  About
                </a>
              </li>
              <li className="nav-item mx-2">
                <a className={`text-decoration-none nav-link ${activeLink === 'features_page' ? 'active' : ''}`} data-section="features_page" data-name="features" href="#features_page">
                  Features
                </a>
              </li>
              <li className="nav-item mx-2">
                <a className={`text-decoration-none nav-link ${activeLink === 'gallery_page' ? 'active' : ''}`} data-section="gallery_page" data-name="gallery" href="#gallery_page">
                  Gallery
                </a>
              </li>
              <li className="nav-item mx-2">
                <a className={`text-decoration-none nav-link ${activeLink === 'faqs_page' ? 'active' : ''}`} data-section="faqs_page" data-name="faq" href="#faqs_page">
                  FAQ's
                </a>
              </li>
              <li className="nav-item mx-2">
                <a className={`text-decoration-none nav-link ${activeLink === 'Pricing_Plans' ? 'active' : ''}`} data-section="Pricing_Plans" data-name="pricing-plans" href="#Pricing_Plans">
                  Pricing
                </a>
              </li>
              <li className="nav-item mx-2">
                <a className={`text-decoration-none nav-link ${activeLink === 'download_page' ? 'active' : ''}`} data-section="download_page" data-name="download-ios-android" href="#download_page">
                  Download
                </a>
              </li>
              <li className="nav-item mx-2">
                <a className={`text-decoration-none nav-link ${activeLink === 'contact_page' ? 'active' : ''}`} data-section="contact_page" data-name="contact-us" href="#contact_page">
                  Contact
                </a>
              </li>
              <li className="nav-item mx-2">
                <button className={`nav-link ${activeLink === 'login' ? 'active' : ''} login-button`} target="_blank" onClick={() => window.open('https://khatatracker.com/business/', '_blank')}>
                  &nbsp; Login
                  <img src={arrow} alt="gif" className="left-arrow" loading='lazy' />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
