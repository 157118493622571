import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";

const Footer = () => {
  const [hovered, setHovered] = useState(null);

  const iconStyle = (icon) => ({
    fontSize: "18px", // Slightly reduced size
    color: hovered === icon ? "green" : "#FFD700",
    textDecoration: "none",
    transition: "color 0.3s ease-in-out",
  });
  return (
    <footer className="footer-area" id="contact_page" style={styles.footer}>
      <div className="container">
        <div className="row align-items-center text-center text-md-start">
          {/* Left Section */}
          <div className="col-md-6 mb-2 mb-md-0">
            <p style={styles.text}>
              &copy; {new Date().getFullYear()} All rights reserved by{" "}
              <a href="https://www.khatatracker.com/" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={styles.link} 
                aria-label="Visit Khata Tracker">
                Khata Tracker
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a href="/privacy/terms_privacy.htm" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={styles.link} 
                aria-label="View Terms and Privacy Policy">
                Terms & Policy
              </a>
            </p>
          </div>
          {/* Right Section - Social Icons */}
          <div className="col-md-6 text-md-end">
            <div className="d-flex justify-content-center justify-content-md-end gap-3">
              {[
                { href: "https://www.facebook.com/share/1AWz1CJPnq/", icon: "bi-facebook" },
                { href: "https://www.instagram.com/khatatracker?igsh=ZjYzMjBmZ2M2Mjl1", icon: "bi-instagram" },
                { href: "https://x.com/i/flow/login?redirect_after_login=%2FKhataTracker", icon: "bi-twitter-x" },
                { href: "https://www.threads.net/@khatatracker", icon: "bi-threads" },
                { href: "https://youtube.com/@khata-tracker-business-account?si=ZBCh4dBYr3GPrh0-", icon: "bi-youtube" },
              ].map(({ href, icon }) => (
                <a
                  key={icon}
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={iconStyle(icon)}
                  onMouseEnter={() => setHovered(icon)}
                  onMouseLeave={() => setHovered(null)}
                  aria-label={`Visit ${icon} website`} // Change this dynamically if needed
                >
                <i className={`bi ${icon}`}></i>
                </a>
              ))}
            </div>

            {/* Manage Account & Refund Policy */}
            <div className="mt-2">
              <a 
                id="footer-link" 
                href="/delete-account" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={styles.link} 
                aria-label="Manage or delete your account"
              >
                Manage Account
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <Link  id="footer-link" to="/refund" target="_blank" style={styles.link}>
                Refund Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    background: "black",
    padding: "15px 0", 
    color: "#fff",
  },
  text: {
    marginBottom: "0",
    fontSize: "18px", 
  },
  link: {
    color: "#FFD700",
    textDecoration: "none",
    fontWeight: "500",
  },
};
export default Footer;
