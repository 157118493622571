import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import file1 from '../assets/privacy/imgs/File (1).jpg';
import file2 from '../assets/privacy/imgs/File (2).jpg';
import file3 from '../assets/privacy/imgs/File.jpg';
import final from '../assets/privacy/imgs/FinalAppIcon.png';
import short1 from '../assets/privacy/imgs/Screenshot (7).png';
import short2 from '../assets/privacy/imgs/Screenshot (8).png';
import short3 from '../assets/privacy/imgs/Screenshot (9).png';
function DeleteAccountInstructions() {
  const [show, setShow] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = (image) => {
    setCurrentImage(image);
    setShow(true);
  };
  const [showContent, setShowContent] = useState('websiteInstructions');
  const showMobileApp = () => setShowContent('mobileAppInstructions');
  const showWebsite = () => setShowContent('websiteInstructions');
  return (
    <>
      <div className="container">
        <div className="card shadow-lg">
          <div className="card-body">
            <h1 className="text-center text-primary">How to Delete Your Account</h1>
            <div id="buttons-container">
              <button className="btn btn-primary" onClick={showMobileApp}>Mobile App</button>
              <button className="btn btn-primary" onClick={showWebsite}>Website</button>
            </div>
            <div id="mobileAppInstructions" style={{ display: showContent === 'mobileAppInstructions' ? 'block' : 'none' }}>
              <h2>To delete your account</h2>
              <ul>
                <li className="image-container">
                  Open Khatabook
                  <img style={{marginLeft:"50px"}}src={final} alt="Khatabook home screen" className="showing-img" onClick={() => handleShow(final)} />
                </li>
                <li className="image-container">
                  Tap Settings -- Login
                  <img style={{marginLeft:"50px"}}src={file3} alt="Settings screen" className="showing-img" onClick={() => handleShow(file3)} />
                </li>
                <li className="image-container">
                  Select Delete Account on the screen
                  <img style={{marginLeft:"50px"}}src={file2} alt="Delete account screen" className="showing-img" onClick={() => handleShow(file2)} />
                </li>
                <li className="image-container">
                  Press Ok
                  <img style={{marginLeft:"50px"}}src={file1} alt="Confirmation screen" className="showing-img" onClick={() => handleShow(file1)} />
                </li>
              </ul>
            </div>
            <div id="websiteInstructions" style={{ display: showContent === 'websiteInstructions' ? 'block' : 'none' }}>
              <h2>To delete your account</h2>
              <ul>
                <li>Delete Account: <a href="/login-delete">Delete your account</a></li>
                <li>
                  <a href="https://www.khatatracker.com/" target="_blank" rel="noopener noreferrer" aria-label="Open Khatatracker website in a new tab">
                    Khatatracker
                  </a>
                </li>
                <li>Scroll down --- Manage Account</li>
                <li className="image-container">
                  Enter your login credentials & tap Login button
                  <img style={{marginLeft:"50px"}} src={short1} alt="Login screen" className="showing-img" onClick={() => handleShow(short1)} />
                </li>
                <li className="image-container">
                  Press Ok
                  <img style={{marginLeft:"50px"}} src={short2} alt="Ok button screen" className="showing-img" onClick={() => handleShow(short2)} />
                </li>
                <li className="image-container">
                  Press Ok
                  <img style={{marginLeft:"50px"}} src={short3} alt="Ok button confirmation screen" className="showing-img" onClick={() => handleShow(short3)} />
                </li>
              </ul>
            </div>
            <div id="ganesh">
              <h2>If you delete your account:</h2>
              <ul>
                <li>Account retrieval is impossible after deleting your Khatabook account.</li>
                <li>Deletion of information may take within 24 hrs.</li>
                <li>Backups containing your data may exist for recovery after the two-day period in case of a disaster or data loss incident.</li>
                <li>Access to your Khatabook information is not available during this deletion period.</li>
                <li>Certain log data may be retained post-deletion, but it will be dissociated from personally identifiable information.</li>
                <li>Regular removal of specific identifiers prevents tracing back to your account.</li>
                <li>Your information shared with other Meta will also be deleted.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentImage && <img src={currentImage} alt="Detailed view" className="modal-image" />}
        </Modal.Body>
      </Modal>
      <style jsx>
  {`
    body {
      background-color: #F4F7F6;
      color: #333;
      font-family: 'Arial', sans-serif;
      margin: 20px;
      transition: all 0.3s ease;
    }
    .card {
      border-radius: 10px;
      border: none;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease-in-out;
    }
    .card:hover {
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
    }
    h1, h2 {
      color: #1AA267;
      transition: color 0.3s ease;
    }
    #buttons-container {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      gap: 20px;
    }
    .btn {
      font-weight: bold;
      transition: background-color 0.3s ease, transform 0.3s ease;
    }
    .btn-primary {
      background-color: #1AA267;
      border-color: #1AA267;
    }
    .btn-primary:hover {
      background-color: #169E56;
      border-color: #169E56;
      transform: scale(1.05);
    }
    .image-container {
      margin: 30px 0;
    }
    .showing-img {
      width: 100%;
      max-width: 300px;
      transition: transform 0.3s ease-in-out;
      cursor: pointer;
    }
    /* Apply hover effect only on the image itself */
    .showing-img:hover {
      transform: scale(1.1);
    }
    #ganesh {
      margin-top: 40px;
    }
    a {
      color: #1AA267;
      text-decoration: none;
      transition: color 0.3s ease;
    }
    a:hover {
      color: #169E56;
      text-decoration: underline;
    }
    .modal .modal-content {
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;
    }
    .modal-header {
      border-bottom: none;
    }
    .modal-body {
      text-align: center;
    }
    .modal-image {
      width: 80%;
      max-width: 500px;
      transition: transform 0.3s ease;
    }
    .modal-image:hover {
      transform: scale(1.1);
    }
    @media (max-width: 768px) {
      .btn {
        font-size: 14px;
      }
      .showing-img {
        width: 80%;
      }
      .modal-image {
        width: 90%;
      }
    }
  `}
</style>
    </>
  );
}
export default DeleteAccountInstructions;
