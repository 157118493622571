import React from 'react';
import './styles.css';
const About = () => {
  return (
    <section className="section-padding" id="about_page">
      <div className="container d-flex justify-content-center align-items-center" style={{paddingTop:"100px"}}>
        <div className="row justify-content-center text-center">
          <div className="col-xs-12 col-md-10">
            <div className="">
              {/* Changed h5 to h2 for better hierarchy */}
              <h2 className="title d-flex align-items-center justify-content-center">
                <span className="mx-3 fs-2 fw-bold pt-3">ABOUT KHATA TRACKER</span>
              </h2>
              <p>
                <span style={{ fontFamily: "inherit", fontWeight: 595 }}>Khata Tracker for Business Accounts</span> is a comprehensive 
                <span style={{ fontFamily: "inherit", fontWeight: 595 }}> financial management solution</span> that simplifies the process 
                of managing <span style={{ fontFamily: "inherit", fontWeight: 595 }}>ledgers, cash transactions, and expenses</span> effortlessly. 

                Whether you're handling <span style={{ fontFamily: "inherit", fontWeight: 595 }}>income, credit, loans, or invoices,</span> 
                Khata Tracker streamlines your entire <span style={{ fontFamily: "inherit", fontWeight: 595 }}>accounting process,</span> allowing 
                you to <span style={{ fontFamily: "inherit", fontWeight: 595 }}>track daily bills and payments, record and categorize income 
                and expenses accurately,</span> and <span style={{ fontFamily: "inherit", fontWeight: 595 }}>monitor budgets and spending 
                patterns</span> through detailed reports and analytics. 

                Designed specifically for <span style={{ fontFamily: "inherit", fontWeight: 595 }}>small businesses, shopkeepers, service 
                providers, and entrepreneurs,</span> this all-in-one <span style={{ fontFamily: "inherit", fontWeight: 595 }}>expense 
                management tool</span> keeps your financial data organized and secure. 

                With real-time insights and detailed transaction histories, Khata Tracker empowers you to maintain complete control over your 
                accounts, ensuring smooth and efficient financial management.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default About;