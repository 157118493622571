import React, { useState, useEffect } from "react";
import '@fortawesome/fontawesome-free/css/all.min.css';
import './pop.css';  
import Banner from '../assets/images/Banner1.webp'
import Modal from 'react-bootstrap/Modal';
import OTPVerification from '../pages/Verify_OTP/verifyotp';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Popup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false); 
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsOpen(true);
  //   }, 10000);  
  //   return () => clearTimeout(timer);
  // }, []);
 
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 3000 && !hasScrolled) {
        setIsOpen(true);
        setHasScrolled(true); 
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolled]);

  const closePopup = () => {
    setIsOpen(false);
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    displayName: '',
    password: '',
    mobilenumber: '',
    re_password:'',

  });
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [re_showPassword, setRe_showPassword] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');
  const [re_passwordValue, setRe_PasswordValue] = useState('');
  const togglePasswordVisibility = () => {

    setShowPassword(!showPassword);
  };
  const toggleREPasswordVisibility = () => {

    setRe_showPassword(!re_showPassword);
  };

  const generateSessionId = () => {
    return 'session_' + Math.random().toString(36).substring(2, 9);
  };

  const handleChange = (e) => {
    setError('');
    const { name, value } = e.target;
    if (name === 'mobilenumber') {
      if (!(/^\d*$/.test(value))) {
        return;
      }
    }
    if (name == 'password') {
      setPasswordValue(value)
    }
    if (name == 're_password') {
      setRe_PasswordValue(value);

    }
    setFormData({
      ...formData,
      [name]: value,
    });

  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const [show, setShow] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleOtpClose = () => setShowOtpModal(false);
  const handleOtpShow = () => {
    setShowOtpModal(true);
    setShow(false)
  }

  const [showPopup, setShowPopup] = useState(false);
  const [showOTPPopup, setShowOTPPopup] = useState(false);
  const [showRegisterPopup, setShowRegisterPopup] = useState(true);
  const handleCloseOTPPopup = () => {
    setShowOTPPopup(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setError('Invalid email format');
      return;
    }

    if (!(re_passwordValue == passwordValue)) {
      setError('Password not matched.')
      return;
    }
    handleOtpShow();
    try {
      const urlEncodedData = new URLSearchParams();
      urlEncodedData.append('email', formData.email);
      urlEncodedData.append('mobilenumber', formData.email);

      const response = await fetch('http://api.khatatracker.com:3000/otpsend', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: urlEncodedData.toString()
      });
      const userdata = await response.json();
      console.log(userdata, 'userdata')

      if (userdata.status === true) {
        
        const sessionId = generateSessionId();
        sessionStorage.setItem('sessionId', sessionId);
        sessionStorage.setItem(`verifyEmail_${sessionId}`, formData.email);
        sessionStorage.setItem(`OTP${sessionId}`, userdata.OTP);
        sessionStorage.setItem('formData', JSON.stringify(formData));
        console.log(userdata, 'userdata')
        console.log(formData.email, 'formData.email')
       
        // navigate('/verifyotp');
        setShowRegisterPopup(false);
        setShowOTPPopup(true);
      } else if (response.data.status == 'webloginfail') {
        setError(response.data.message);
        return;
      } else {
        setError(userdata.message);
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred. Please try again.');
    }
  };

  const location = useLocation();
  const [otp, setOTP] = useState(''); // Single input for OTP
  const sessionId = sessionStorage.getItem('sessionId');
  const storedEmail = sessionStorage.getItem(`verifyEmail_${sessionId}`);
  const formDataval = JSON.parse(sessionStorage.getItem('formData'));
  const [email, setEmail] = useState(storedEmail || '');
  useEffect(() => {
   
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.pointerEvents = 'none';
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.pointerEvents = 'auto'; 
    }
    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.pointerEvents = 'auto'; 
    };
  }, [isOpen]);
  return (
    <div>
    <div className={`popup-container ${isOpen ? "show" : ""}`}>
      <div className="popup-content" style={{ pointerEvents: 'auto' }}>
        <button className="popup-close text-align-center" onClick={closePopup}><i className="fas fa-times"></i>
        </button> 
        <img src={Banner} alt="Banner" onClick={handleShow} loading="lazy" />
      </div>
    </div>
    <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className="custom-modal-close" style={{ background: 'rgb(8, 99, 81)', color: '#FFFFFF' }}>
                  <Modal.Title>Register</Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ background: '#EFFFFA' }} >
                  <Form onSubmit={handleSubmit}>

                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        id="name"
                        placeholder="Enter your name"
                        type="text"
                        name="displayName"
                        value={formData.displayName}
                        onChange={handleChange}
                        autoComplete="off"
                        required
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        id="email"
                        placeholder="Enter your email"
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        autoComplete="off"
                        required
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        id="mobile"
                        type="text"
                        name="mobilenumber"
                        placeholder="Enter mobile number"
                        value={formData.mobilenumber}
                        onChange={handleChange}
                        autoComplete="off"
                        minLength={10}
                        maxLength={10}
                        required
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <div className="password-wrapper">
                      <Form.Control
                        id="password"
                        placeholder="Enter password"
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        autoComplete="off"
                        required
                      />
                       </div>
                      {/* <div onClick={togglePasswordVisibility} className="eye-icon">
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </div> */}
                     
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Re-Enter Password</Form.Label>
                      <div className="password-wrapper">
                      <Form.Control
                        id="re_password"
                        type={re_showPassword ? 'text' : 'password'}
                        name="re_password"
                        placeholder="Re-enter password"
                        value={formData.re_password}
                        onChange={handleChange}
                        autoComplete="off"
                        required
                      />
                       </div>
                       {/* <div onClick={toggleREPasswordVisibility} className="eye-icons">
                          {re_showPassword ? <FaEye /> : <FaEyeSlash />} 
                      </div> */}
                     
                    </Form.Group>
                    <br />
                    {error && (<p style={{ color: 'red',textAlign:'center'}}>{error}</p>)} 

                    <div className="d-flex justify-content-center mt-3">

                      <Button type="submit" style={{ background: '#086351' }} >
                        Submit
                      </Button>
                    </div>
                  </Form>

                </Modal.Body>

              </Modal>

              <Modal show={showOtpModal} onHide={handleOtpClose}>
                <Modal.Header closeButton className="custom-modal-close" style={{ background: 'rgb(8, 99, 81)', color: '#FFFFFF' }}>
                  <Modal.Title>Verification Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p style={{fontSize:'0.85em',textAlign:'center',color:'#086351'}}>Verification Code sent to your email: {email}</p>
                  <OTPVerification />
                </Modal.Body>
              </Modal>
    </div>
  );
};
export default Popup;
