import React from 'react';

const CancellationRefundPolicy = () => {
  return (
    <div className="container mt-5">
      <h3 className="text-center mb-4">Cancellation & Refund Policy</h3>
      <h4><strong>Effective Date:</strong> [17/9/2024]</h4>
      <p>
        ANJU SIIMA TECHNOLOGIES PRIVATE LIMITED is committed to assisting its customers to the fullest extent possible and, as such, has adopted a flexible cancellation policy. Under this policy:
      </p>
      <ul className="list-group">
        <li className="list-group-item">
          Cancellations will be considered only if the request is made shortly after placing the order. However, cancellations may not be accepted if the order has already been processed or communicated to the relevant parties.
        </li>
        <li className="list-group-item">
          For paid services, your subscription will be valid for a specific duration. Renewal is required before the expiration of the license.
        </li>
        <li className="list-group-item">
          Khata Tracker does not automatically renew subscriptions. You will need to manually renew your subscription before expiration.
        </li>
        <li className="list-group-item">
          Refund requests must be submitted within 7 days of purchase. Requests submitted after this period may not be eligible for refunds.
        </li>
      </ul>
      <style jsx>{`
        .container {
  background-color: #f8f9fa;
  padding: 30px;
  border-radius: 8px;
}

h3 {
  font-family: 'Nunito', sans-serif;
  color: #086351;
}

ul {
  font-size: 1.1rem;
}

.list-group-item {
  font-size: 1rem;
  color: #333;
  border-radius: 4px;
}

.list-group-item:hover {
  background-color: #e2e6ea;
}

           `}
      </style>
    </div>
  );
};

export default CancellationRefundPolicy;
