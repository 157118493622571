import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import './styles.css';
import checkicon from "../assets/images/check-icon.webp"
import checkIcon from "../assets/images/CheckIcon.webp"
const plans = [
  {
    title: "Free Users",
    price: "₹ 0",
    discount: "7 Days",
    duration: "Free Trail",
    buttonText: "Active",
    features: [
      "Offline Access",
      "Personal Expenses Tracking",
      "Business Expenses Tracking",
      "Multiple Business Profiles",
      "Dedicated Support(Limited)",
      "Comprehensive Reports(Limited)",
      "Invoice Generation(Limited)",
      "Payment Reminder(Limited)",
      "Inventory Management(Limited)"
    ],
  },
  {
    title: "Mobile Plan",
    price: "₹ 999",
    oldPrice: "₹ 1999",
    discount: "50% off",
    duration: "Life Time",
    buttonText: "Upgrade",
    features: [
      "Complete Mobile Access",
      "Ad-Free Experience",
      "Offline Access",
      "Payment Reminders",
      "Personal Expense Tracking",
      "Business Expense Tracking",
      "Multiple Business Profiles",
      "Dedicated Support",
      "Comprehensive Reports",
      "Bill Attachment Feature",
      "Invoice Generation",
      "Inventory Management",
      "Accounts",
    ],
  },
  {
    title: "Web Plan",
    price: "₹ 1599",
    oldPrice: "₹ 2999",
    discount: "50% off",
    duration: "Yearly",
    buttonText: "Upgrade",
    features: [
      "Complete Web Access",
      "Ad-Free Experience",
      "Offline Access",
      "Payment Reminders",
      "Personal Expense Tracking",
      "Business Expense Tracking",
      "Multiple Business Profiles",
      "Dedicated Support",
      "Comprehensive Reports",
      "Bill Attachment Feature",
      "Invoice Generation",
      "Inventory Management",
      "Accounts",
    ],
  },
  {
    title: "Mobile and Web Plan",
    price: "₹ 1999",
    oldPrice: "₹ 3999",
    discount: "50% off",
    duration: "Yearly",
    buttonText: "Premium",
    recommended: true,
    features: [
      "Accessible on Both Mobile and Web",
      "Ad-Free Experience",
      "Offline Access",
      "Payment Reminders",
      "Personal Expense Tracking",
      "Business Expense Tracking",
      "Multiple Business Profiles",
      "Dedicated Support",
      "Comprehensive Reports",
      "Bill Attachment Feature",
      "Invoice Generation",
      "Inventory Management",
      "Accounts",
    ],
  },
];

const PricingPlans = () => {
  return (
    <section className="container text-center py-5" id="Pricing_Plans">
      <div style={{marginTop:"70px" }}>
      <h2 className="mb-3">Pricing Plans</h2>
      <p>Choose a plan that suits your business</p>
      <div className="row">
        {plans.map((plan, index) => (
          <div key={index} className="col-12 col-md-6 col-lg-3 mb-5 mt-5">
            <div className="plan-title" style={{ borderRadius: "25px 25px 0px 0px" }}>
              <h3 className="card-title pt-3" style={{color:"black",fontSize:"20px"}}>{plan.title}</h3>
            </div>
            <div className={`card p-3 h-100  ${plan.recommended ? "recommended" : ""}`} style={{ borderRadius: "0px 0px 25px 25px", }}>
              <div className="plan-price">
                <h3 style={{color:'black'}}>{plan.price}</h3>
              </div>
              {plan.discount && (
                <p className="text-danger mb-0">
                  {plan.oldPrice ? <del>{plan.oldPrice}</del> : ""} {plan.discount}
                </p>
              )}
              <p className="text-success mb-0">{plan.duration}</p>
              <div className="text-center">
                <button className="btn btn-outline-primary buttonText"
                  target="_blank"
                  onClick={() => window.open('https://khatatracker.com/business/', '_blank')}
                >{plan.buttonText}</button>
              </div>
              <ul className="list-unstyled mt-3 checkicon">
                {plan.features.map((feature, idx) => {
                  const parts = feature.split("(Limited)");
                  const isFreeUser = plan.title === "Free Users";
                  const isGreenIcon = isFreeUser && idx < 4; // First 4 are green
                  const isYellowIcon = isFreeUser && idx >= 4; // Remaining are yellow

                  return (
                    <li key={idx} className="text-start d-flex align-items-center ">
                      <img
                        src={isGreenIcon ? checkicon : isYellowIcon ? checkIcon : checkicon}
                        alt="check"
                        className="me-2"
                        width={17}
                        height={16}
                      />
                      <span>
                        {parts[0]}
                        {parts.length > 1 && <span className="text-warning">(Limited)</span>}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ))}
      </div>
      </div>
    </section>
  );
};
export default PricingPlans;
