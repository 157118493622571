import React from 'react';
import { useState, useEffect } from 'react';
import './styles.css';
// import Feature from '../assets/images/feature-image (1).webp';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket } from '@fortawesome/free-solid-svg-icons/faRocket';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip';
import { faCloudDownload } from '@fortawesome/free-solid-svg-icons/faCloudDownload';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faLaptop } from '@fortawesome/free-solid-svg-icons/faLaptop';
import { faCoins } from '@fortawesome/free-solid-svg-icons/faCoins';

const Features = () => {
  const [featureSrc, setFeatureSrc] = useState(null);
  useEffect(() => {
    import('../assets/images/feature-image (1).webp').then((image) => {
      setFeatureSrc(image.default);
    });
  }, []);
  useEffect(() => {
    const section = document.getElementById('features_page');
  
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          section.classList.add('lazy-bg'); // Load background
          observer.unobserve(section);
        }
      });
    });
  
    if (section) observer.observe(section);
  }, []);
  return (
    <div>
      <section className="feature-area section-padding-top" id="features_page" style={{ textAlign: 'left' }}>
        <div className="container mt-5">
          {/* Centered Features Page Title Section */}
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-6">
            <div className="page-title d-flex flex-column align-items-center text-center">
              <h5 className="title">Key Features</h5> {/* Changed from <h5> to <h2> */}
              <div className="space-10"></div>
              <h3 style={{ marginTop: "-5px", marginBottom: "10px" }}>
                Powerful Features for Seamless Financial Management
              </h3>
              <div className="space-60"></div>
            </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="service-box wow fadeInUp" data-wow-delay="0.2s">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faRocket} />
                </div>
                <h4>High-Speed Performance</h4>
                <p>Lightning-fast downloads and minimal data usage.</p>
              </div>
              <div className="space-60"></div>
              <div className="service-box wow fadeInUp" data-wow-delay="0.4s">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faPaperclip} />
                </div>
                <h4>Effortless Editing</h4>
                <p>Customize transaction categories and streamline workflows.</p>
              </div>
              <div className="space-60"></div>
              <div className="service-box wow fadeInUp" data-wow-delay="0.6s">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faCloudDownload} />
                </div>
                <h4>Secure Cloud Storage</h4>
                <p>Encrypted and reliable <b>data protection</b> for peace of mind.</p>
              </div>
            </div>

            <div className="hidden-xs hidden-sm col-md-4">
              <figure className="mobile-image">
              <img src={featureSrc} alt="Feature Photo" className="lazy" loading="lazy"/>
              </figure>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="service-box wow fadeInUp" data-wow-delay="0.4s">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faLaptop} />
                </div>
                <h4>Fully Responsive Design</h4>
                <p>Auto-adjusts for a seamless experience on all devices.</p>
              </div>
              <div className="space-60"></div>
              <div className="service-box wow fadeInUp" data-wow-delay="0.2s">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faClock} />
                </div>
                <h4>Custom Alerts & Reminders</h4>
                <p>Stay on top of finances with scheduled notifications and reminders.</p>
              </div>
              <div className="space-60"></div>
              <div className="service-box wow fadeInUp" data-wow-delay="0.6s">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faCoins}/>
                </div>
                <h4>Smart Budget Management</h4>
                <p>Set budgets and receive alerts when approaching limits.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Features;
