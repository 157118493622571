import React, { useState, useEffect, useRef } from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    usedIn: "Khata Tracker",
    toEmail: "khatatracker@gmail.com",
  });

  const [captchaVerified, setCaptchaVerified] = useState(false);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    const handlePageHide = (event) => {
      if (window.socket) {
        window.socket.close();
        window.socket = null;
      }
    };
  
    window.addEventListener("pagehide", handlePageHide);
    window.addEventListener("beforeunload", handlePageHide);
  
    return () => {
      window.removeEventListener("pagehide", handlePageHide);
      window.removeEventListener("beforeunload", handlePageHide);
    };
  }, []);
  
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCaptchaChange = (value) => {
    console.log("reCAPTCHA value:", value);
    setCaptchaVerified(!!value);
  };  

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaVerified) {
      alert("Please verify the CAPTCHA.");
      return;
    }

    fetch("https://api.khatatracker.com/sendEmail", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        console.log("Raw response:", response);
        return response.json();
      })
      .then((data) => {
        console.log("Server response:", data);
        alert(data.message);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Failed to send email. Please try again.");
      })    
      .then((response) => response.json())
      .then((data) => {
        alert(data.message);
        setFormData({ name: "", email: "", subject: "", message: "" });

        // ✅ Reset CAPTCHA
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
        setCaptchaVerified(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Failed to send email. Please try again.");
      });
  };

  return (
    <div>
      <section id="contact_page" style={{ textAlign: "left" }}>
        <div className="section-padding" style={{ paddingTop: "80px" }}>
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <div className="page-title text-center">
                  <br />
                  <h5 className="title">Contact Us</h5>
                  <div className="space-60"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-4">
                <div className="footer-box">
                  <div className="box-icon">
                    <FontAwesomeIcon icon={faLocationDot} />
                  </div>
                  <p>Madhapur, Hyderabad, Telangana - 500081</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <div className="footer-box">
                  <div className="box-icon">
                    <FontAwesomeIcon icon={faPhone} />
                  </div>
                  <p>+91 8699793623</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <div className="footer-box">
                  <div className="box-icon">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <p>khatatracker@gmail.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-lg-12 col-md-12">
              <form onSubmit={handleSubmit}>
                <div className="form-row">
                  <div className="row">
                    <div className="form-group col-xs-12 col-lg-6 col-md-6">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Enter Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group col-xs-12 col-lg-6 col-md-6">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Enter Your Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group col-xs-12 col-lg-12 col-md-12">
                    <label>Subject</label>
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      placeholder="Enter Subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group col-xs-12 col-lg-12 col-md-12">
                    <label>Message</label>
                    <textarea
                      className="form-control"
                      name="message"
                      placeholder="Type Your Message Here..."
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                </div>

                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LcH1xUqAAAAAMb_59kdhJIbhb_APfSPx78DVX59"
                  onChange={handleCaptchaChange}
                />
                <center>
                  <div className="text-center span6">
                    <button
                      type="submit"
                      className="submit"
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        padding: "10px 10px",
                        backgroundColor: "#0a662e",
                        color: "#ffffff",
                        border: "2px solid #0a662e",
                        // width : "10%",
                        fontSize : "20px"
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </center>
              </form>
              <div
                id="successMessage"
                style={{ display: "none", color: "green" }}
              >
                Form submitted successfully!
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
