import React, { useState, useEffect, Suspense } from 'react';
import './home.css';
import img from '../assets/images/destop.webp';
import playstore from '../assets/images/PlayStoreBadge.webp';
import google from '../assets/images/GooglePlayStore.webp';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useLocation, useNavigate } from 'react-router-dom';
import Popup from './popup';
import axios from 'axios';
import OTPVerification from '../pages/Verify_OTP/verifyotp';

const Home = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    displayName: '',
    password: '',
    mobilenumber: '',
  });
  const [error, setError] = useState('');
  const [weberror, setWeberror] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [re_showPassword, setRe_showPassword] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');
  const [re_passwordValue, setRe_PasswordValue] = useState('');
  
  const generateSessionId = () => 'session_' + Math.random().toString(36).substr(2, 9);

  const handleChange = (e) => {
    setError('');
    const { name, value } = e.target;
    if (name === 'mobilenumber' && !(/^\d*$/.test(value))) {
      return;
    }
    if (name === 'password') setPasswordValue(value);
    if (name === 're_password') setRe_PasswordValue(value);

    setFormData({ ...formData, [name]: value });
  };

  const [show, setShow] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleOtpClose = () => setShowOtpModal(false);
  const handleOtpShow = () => {
    setShowOtpModal(true);
    setShow(false);
  };

  const [showPopup, setShowPopup] = useState(false);
  const [showOTPPopup, setShowOTPPopup] = useState(false);
  const [showRegisterPopup, setShowRegisterPopup] = useState(true);
  const handleCloseOTPPopup = () => setShowOTPPopup(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setError('Invalid email format');
      return;
    }
    
    if (re_passwordValue !== passwordValue) {
      setError('Password and Re-entered password do not match.');
      return;
    }
    try {
      const urlEncodedData = new URLSearchParams();
      urlEncodedData.append('email', formData.email);
      urlEncodedData.append('mobilenumber', formData.mobilenumber);

      const response = await fetch('http://api.khatatracker.com:3000/otpsend', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: urlEncodedData.toString(),
      });

      const userdata = await response.json();
      console.log(userdata, 'userdata');
      setWeberror(userdata.message);

      if (userdata.status === true) {
        const sessionId = generateSessionId();
        sessionStorage.setItem('sessionId', sessionId);
        sessionStorage.setItem(`verifyEmail_${sessionId}`, formData.email);
        sessionStorage.setItem(`OTP${sessionId}`, userdata.OTP);
        sessionStorage.setItem('formData', JSON.stringify(formData));

        setShowRegisterPopup(false);
        setShowOTPPopup(true);
        handleOtpShow();
      } else {
        setError(userdata.message);
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred. Please try again.');
    }
  };
  const location = useLocation();
  const [otp, setOTP] = useState('');
  const sessionId = sessionStorage.getItem('sessionId');
  const storedEmail = sessionStorage.getItem(`verifyEmail_${sessionId}`);
  const formDataval = JSON.parse(sessionStorage.getItem('formData'));
  const [email, setEmail] = useState(storedEmail || '');

  return (
    <section className="khata-tracker container-fluid" id="home_page">
      <div className="row align-items-center justify-content-between mx-4" style={{ paddingTop: "100px", paddingBottom: "50px" }}>
    
        {/* Left-aligned text section for all screen sizes with consistent padding */}
        <div className="col-lg-5 col-md-6 col-sm-12 text-section" style={{ fontFamily: "Nunito", paddingLeft: '20px', paddingRight: '20px' }}>
          <p className="display-4 mt-5 container" style={{ fontFamily: "Verdana, Arial, Helvetica, sans-serif", textAlign: 'left', marginLeft: "5px",fontSize:"50px" }}>
            Simplify Your Business Accounts with <span style={{ color: "#99FFEA" }}>Khata Tracker!</span>
          </p>
          <Suspense fallback={<h5>Loading...</h5>}>
            <h5 style={{ padding: "20px", fontFamily: "sans-serif", textAlign: 'left' }}>
              <b>Khata Tracker</b> is your go-to <b>finance management app</b> that helps you manage cash flow efficiently. Ideal for <b>small businesses, retailers, and entrepreneurs,</b> Khata Tracker makes it simple to track <b>daily income, expenses, sales, purchases, and all credit and debit entries.</b>
              Whether you run a shop, manage services, or handle business accounts, this powerful <b>ledger management app</b> ensures that your day-to-day financial transactions are recorded effortlessly.
            </h5>
          </Suspense>
    
          {/* Button Section */}
          <div className="buttons my-4" id="registerbuttons" style={{ marginRight: "25%" }}>
            <button onClick={handleShow} className="trail mb-2">Start 7 Day Free Trial</button>
            <button className="trails" onClick={() => window.open('https://khatatracker.com/business/register', '_blank')}>Register Now</button>
          </div>
    
          {/* Modal for Registration */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton className="custom-modal-close" style={{ background: 'rgb(8, 99, 81)', color: '#FFFFFF' }}>
              <Modal.Title>Register</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ background: '#EFFFFA', border: '1px solid rgb(8, 99, 81)' }}>
              <Form onSubmit={handleSubmit} style={{ textAlign: 'left' }}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    id="name"
                    placeholder="Enter your name"
                    type="text"
                    name="displayName"
                    value={formData.displayName}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    id="email"
                    placeholder="Enter your email"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control
                    id="mobile"
                    type="text"
                    name="mobilenumber"
                    placeholder="Enter mobile number"
                    value={formData.mobilenumber}
                    onChange={handleChange}
                    autoComplete="off"
                    minLength={10}
                    maxLength={10}
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    id="password"
                    placeholder="Enter password"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>Re-Enter Password</Form.Label>
                  <Form.Control
                    id="re_password"
                    type={re_showPassword ? 'text' : 'password'}
                    name="re_password"
                    placeholder="Re-enter password"
                    value={formData.re_password}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                </Form.Group>
                <div className="d-flex justify-content-center mt-1">
                  <Button type="submit" style={{ background: '#086351' }}>Submit</Button>
                </div>
                {error && <div style={{ color: 'red', textAlign: "center" }}>{error}</div>}
              </Form>
            </Modal.Body>
          </Modal>
    
          {/* OTP Modal */}
          <Modal show={showOtpModal} onHide={handleOtpClose} backdrop="static">
            <Modal.Header closeButton className="custom-modal-close" style={{ background: 'rgb(8, 99, 81)', color: '#FFFFFF' }}>
              <Modal.Title>Verification Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{ fontSize: '0.85em', textAlign: 'center', color: '#086351' }}>Verification Code sent to your email: {formData.email}</p>
              <OTPVerification />
            </Modal.Body>
          </Modal>
    
          <p style={{ textAlign: 'left', marginLeft: "6%" }} id="paidplans">
            Paid plans starting from <span style={{ color: '#2DF9D0', textDecoration: 'underline' }}>₹999/-</span>
          </p>
    
          {/* Play Store Section */}
          <div className="play-store mt-4 content-align-center" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <p style={{ color: "#fff" }} className="ms-4 me-4 ">Also available here:</p>
            <div className="d-flex align-items-center ms-4 me-4 ">
              <a href="https://apps.apple.com/in/app/khata-book-spending-tracker/id1517793704" target="_blank" rel="noopener noreferrer">
                <img src={playstore} alt="App Store" className="me-2" loading='lazy'/>
              </a>&nbsp;&nbsp;
              <a href="https://play.google.com/store/apps/details?id=personal.expense.budget.pocket.moneytracker&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                <img src={google} alt="Play Store" loading='lazy'/>
              </a>
            </div>
          </div>
    
        </div>  
        {/* Image Section */}
        <div className="col-lg-6 col-md-6 col-sm-12 image-section p-0">
          <div className="device-images d-flex justify-content-center">
            <img src={img} alt="Desktop View" className="img-fluid desktop-img" loading='lazy' />
          </div>
        </div>
    
      </div>
         
<style jsx>{`
  .modal {
    justify-content: center;
    align-items: center;
    z-index: 100000;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .bgColor{
    background-color: #DDD5CC;
  }
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
`}</style>
      <Popup />
    </section>
  );
};

export default Home;

